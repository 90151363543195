import React from 'react';
import { DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';

interface SearchFormProps {
    onSearch: (data: FormData) => void;
    onReset: () => void;
    control: any;
    handleSubmit: any;
    reset: any;
}

interface FormData {
    dateFrom: string | null;
    dateTo: string | null;
}

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, onReset, control, handleSubmit, reset }) => {

    const onSubmit = (data: FormData) => {
        const formattedData = {
            dateFrom: data.dateFrom
                ? dayjs(data.dateFrom)
                      .startOf('day')
                      .add(7, 'hour') // Adjust to 00:00:00 in UTC+7
                      .toISOString()
                : null,
            dateTo: data.dateTo
                ? dayjs(data.dateTo)
                      .endOf('day')
                      .add(7, 'hour') // Adjust to 23:59:59 in UTC+7
                      .toISOString()
                : null,
        };
        onSearch(formattedData);
    };

    const onResetForm = async () => {
        await reset({ dateFrom: null, dateTo: null });
        onReset();
    }

    return (
        <div className="mt-4 sm:px-6">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap items-center">
                <Controller
                    name="dateFrom"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            placeholder="From"
                            format={'DD/MM/YYYY'}
                            allowClear={false}
                            onChange={(date) => field.onChange(dayjs(date))}
                            value={field.value ? dayjs(field.value) : ''}
                            className="w-3/4 md:w-72 md:ml-0 ml-5"
                        />
                    )}
                />

                <Controller
                    name="dateTo"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            placeholder="To"
                            format={'DD/MM/YYYY'}
                            allowClear={false}
                            onChange={(date) => field.onChange(dayjs(date))}
                            value={field.value ? dayjs(field.value) : ''}
                            className="w-3/4 md:w-72 md:mt-0 mt-2 md:px-5 ml-5"
                        />
                    )}
                />

                {/* Button Search */}
                <Button
                    onClick={handleSubmit(onSubmit)}
                    className="ml-5 py-3 px-10 md:mt-0 mt-5 bg-purple-500 text-white rounded-md"
                    type="primary"
                >
                    Search
                </Button>

                {/* Button Reset */}
                <Button
                    onClick={() => onResetForm()}
                    className="ml-5 py-3 px-10 md:mt-0 mt-5 bg-gray-600 text-white rounded-md"
                    type="default"
                >
                    Reset
                </Button>
            </form>
        </div>
    );
}

export default SearchForm;