import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';

interface DataUser {
    gritId: string;
    username: string;
    countryCode: string;
    countryName: string;
    twoFaStatus: {
        registerStatus: boolean;
        enabledLogin: boolean;
        enabledWithdrawal: boolean;
        enabledTransfer: boolean;
    };
}

export default function Reset2fa() {
    const [searchValue, setSearchValue] = useState('');
    const [dataUser, setDataUser] = useState<DataUser | null>(null);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const token = localStorage.getItem('token');

    function inputValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.value;
        setSearchValue(value);
    }

    function handleSearch() {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/manage/fetch-user?uid=${searchValue}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setDataUser(res.data);
                setDisabledBtn(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleSaveReset() {
        setDisabledBtn(true);

        if (dataUser) {
            Swal.fire({
                title: `Are you sure to reset 2FA ${dataUser.gritId}`,
                inputAttributes: { autocapitalize: 'on' },
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(
                            `${process.env.REACT_APP_ADMIN_API}/manage/reset-2fa-user`,
                            {
                                gritId: dataUser.gritId,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .then((res) => {
                            setDataUser({
                                ...dataUser,
                                twoFaStatus: {
                                    registerStatus: false,
                                    enabledLogin: false,
                                    enabledWithdrawal: false,
                                    enabledTransfer: false,
                                },
                            });
                            Swal.fire({
                                title: res.data.message,
                                icon: 'success',
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
                setDisabledBtn(false);
            });
        } else {
            setDisabledBtn(false);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <main className={`w-full h-[1000px] border`}>
            <div className="w-full h-auto flex justify-center items-center mt-12 md:mt-0">
            <img className="w-8 h-8 mr-3" src="/imgs/icons/2fa.png" alt="2fa icon" />
                <h1 className="text-3xl">Reset 2FA Profile |</h1>
                <img className="w-[150px] h-auto" src="/imgs/logo.jpg" alt="brand-logo" />
            </div>

            <div className="flex w-10/12 md:w-8/12 mx-auto mt-4 ">
                <input
                    value={searchValue}
                    type="text"
                    placeholder="ค้นหา GRIT ID"
                    className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl"
                    onChange={(e) => inputValue(e)}
                />
                <button className="ml-4 bg-purple-500 text-white rounded-xl px-4" onClick={handleSearch}>
                    Search
                </button>
            </div>

            <div className="w-10/12 md:w-8/12 h-auto flex mt-2.5 mx-auto justify-center items-center">
                <div className="w-full rounded-xl bg-gray-200 h-auto p-2.5 flex items-center justify-center md:h-56">
                    {dataUser ? (
                        <>
                            <div className="flex flex-col justify-center items-center w-full h-full md:flex-row">
                                <div className="w-full h-full  flex flex-col ml-6 text-xl justify-center p-2.5">
                                    <p className="text-sm sm:text-xl py-1">GRIT ID : {dataUser.gritId}</p>
                                    <p className="text-sm sm:text-xl py-1">Username : {dataUser.username}</p>
                                    <p className="text-sm sm:text-xl">
                                        Country : {dataUser.countryName} ({dataUser.countryCode})
                                        <img
                                            src={`/imgs/flags/${dataUser.countryCode.toLowerCase()}.svg`}
                                            alt={`${dataUser.countryCode} flag`}
                                            className="inline-block ml-2"
                                            style={{ width: '40px', height: '40px' }}
                                        />
                                    </p>
                                </div>
                                <div className="w-full h-full flex flex-col justify-center items-center text-xl mb-2.5 md:mb-0">
                                    <p className="w-10/12 mx-auto my-2.5 flex pl-3 items-center text-white bg-gray-600 h-12 rounded-xl text-lg">
                                        <span
                                            className={`${
                                                dataUser.twoFaStatus?.registerStatus ? 'bg-green-500' : 'bg-red-500'
                                            } w-4 h-4 rounded-full mr-3`}
                                        ></span>{' '}
                                        2FA Registration
                                    </p>
                                    <p className="w-10/12 mx-auto bg-gray-600 text-white flex pl-3 items-center h-12 rounded-xl text-lg">
                                        <span
                                            className={`${
                                                dataUser.twoFaStatus?.enabledLogin ? 'bg-green-500' : 'bg-red-500'
                                            } w-4 h-4 rounded-full mr-3`}
                                        ></span>{' '}
                                        2FA Login
                                    </p>
                                    <p className="w-10/12 mx-auto bg-gray-600 mt-2.5 text-white flex pl-3 items-center h-12 rounded-xl text-lg">
                                        <span
                                            className={`${
                                                dataUser.twoFaStatus?.enabledWithdrawal ? 'bg-green-500' : 'bg-red-500'
                                            } w-4 h-4 rounded-full mr-3`}
                                        ></span>{' '}
                                        2FA Withdrawal
                                    </p>
                                    <p className="w-10/12 mx-auto bg-gray-600 mt-2.5 text-white flex pl-3 items-center h-12 rounded-xl text-lg">
                                        <span
                                            className={`${
                                                dataUser.twoFaStatus?.enabledTransfer ? 'bg-green-500' : 'bg-red-500'
                                            } w-4 h-4 rounded-full mr-3`}
                                        ></span>{' '}
                                        2FA Transfer
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <p>No user data found</p>
                    )}
                </div>
            </div>
            <div className="w-10/12 md:w-8/12 h-auto flex mt-2.5 mx-auto justify-center items-center">
                <button
                    className="bg-purple-500 text-white rounded-lg py-3 px-16 mt-3 disabled:bg-gray-400 text-lg"
                    onClick={handleSaveReset}
                    disabled={disabledBtn}
                >
                    Reset 2FA
                </button>
            </div>
        </main>
    );
}
