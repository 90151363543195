import axios from "axios";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Table, Space, Typography } from 'antd';

type Props = {};

export default function AddAds({}: Props) {
  const [selection, setSelection] = useState<"image" | "youtube" | "">("image");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [embedUrl, setEmbedUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [bannerList, setBannerList] = useState([]);
  const [filePromotionUpload, setFilePromotionUpload] = useState<File | null>(null);

  const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'URL',
        dataIndex: 'image_url',
        key: 'image_url',
        render: (text: any, record: any) => (
          <Space>
            <Typography.Link href={record.image_url} target="_blank">{record.image_url}</Typography.Link>
          </Space>
        ),
    },
    {
        title: 'Upload Date',
        dataIndex: 'upload_date',
        key: 'upload_date',
        render: (text: string) => {
          const readableDate = new Date(text).toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          return readableDate;
        },
    },
    {
      title: 'Action',
      key: 'action',
      sorter: true,
      render: (text: any, record: any) => (
        <Space size="middle">
          <a href="#" onClick={() => deleteBanner(record.banner_id)}>Delete</a>
        </Space>
      ),
    },
];

  useEffect(() => {
    loadBannerData();
  }, []);

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYoutubeUrl(e.target.value);
    setImageUrl(""); // Clear the image when a YouTube URL is entered
  };

  const token = localStorage.getItem("token");
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFilePromotionUpload(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageUrl(event.target?.result as string);
        setYoutubeUrl(""); // Clear the YouTube URL when an image is uploaded
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const videoId = youtubeUrl.split("v=")[1]?.split("&")[0];
    if (videoId) {
      setEmbedUrl(`https://www.youtube.com/embed/${videoId}`);
    } else {
      setEmbedUrl("");
    }
  }, [youtubeUrl]);

  function updatePromotion() {
    const payload = filePromotionUpload
      ? { banner: filePromotionUpload }
      : { youtube_url: youtubeUrl };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const url = `${process.env.REACT_APP_ADMIN_API}/manage/upload-banner`;

    axios
      .post(url, payload, { headers })
      .then((res) => {
        setEmbedUrl('');
        setImageUrl('');
        loadBannerData();
        Swal.fire("", "อัพโหลดโปรโมชั่น (Ads) สำเร็จ", "success");
      })
      .catch((err) => {
        Swal.fire("", "เกิดข้อผิดพลาดในการอัพโหลดโปรโมชั่น (Ads)", "error");
      });
  }

  function loadBannerData() {
    const token = localStorage.getItem('token');

    let path = `${process.env.REACT_APP_ADMIN_API}/manage/banner-list`

    axios.get(path, {
        headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
        setBannerList(res.data.data.map((banner: any, index: number) => ({ ...banner, index: index + 1 })));
    })
    .catch((err) => {
        console.log(err);
    });
  }

  function deleteBanner(bannerId: string) {
    Swal.fire({
      title: "Delete Banner",
      text: "ยืนยันเพื่อ Delete Banner",
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
          axios.post(
              `${process.env.REACT_APP_ADMIN_API}/manage/delete-banner`,
              { bannerId },
              { headers: { Authorization: `Bearer ${token}` }}
          )
          .then(() => {
          Swal.fire({
              title: 'Delete Banner สำเร็จ',
              icon: 'success'
          });
      
          loadBannerData();
          })
          .catch((err) => {
          Swal.fire(`${err.message}`, "", "error");
          console.log(err);
          });
      }
    });
  }

  return (
    <main className="flex flex-col justify-top items-center w-full mt-10">
      <div className="grid grid-cols-1 border w-full md:w-1/2">
        <div className="w-full h-full border p-4">
          <h1 className="w-full text-center text-3xl mt-2.5">
            Banner Promotion
          </h1>
          <div className="grid grid-cols-2 mt-4 ">
            <div
              onClick={() => setSelection("image")}
              className={`w-full h-12 flex justify-center items-center mr-4 cursor-pointer   ${
                selection == "image" ? "bg-purple-500 text-white" : ""
              } `}
            >
              อัพโหลดรูป
            </div>
            <div
              onClick={() => setSelection("youtube")}
              className={`w-full h-12 flex justify-center items-center mr-4  cursor-pointer  ${
                selection == "youtube" ? "bg-purple-500 text-white" : ""
              } `}
            >
              อัพโหลด YouTube
            </div>
          </div>

          {selection === "youtube" && (
            <input
              type="text"
              placeholder="Enter YouTube URL"
              value={youtubeUrl}
              onChange={handleUrlChange}
              className="w-full border border-gray-500 p-2 mt-4 rounded-full text-center outline-none"
            />
          )}

          {selection === "image" && (
            <div className="flex items-center justify-center w-full mt-4">
              <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">คลิกเพื่ออัพโหลดรูป</span>{" "}
                    หรือ ลากรูปมาวาง
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          )}
        </div>

        { (imageUrl || embedUrl) &&  <div className="w-full h-full">
          <h1 className="w-full text-center text-3xl mt-2.5">Preview Ads</h1>
          <div className=" w-full mx-auto ">
            {imageUrl ? (
              <img
                className="w-[300px] h-[400px] object-contain mx-auto  md:w-full"
                src={imageUrl}
                alt="Ad Preview"
              />
            ) : embedUrl ? (
              <div className="w-full h-full mt-4">
                <iframe
                  width="100%"
                  height="400"
                  src={embedUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ) : (
              <div className="flex justify-center items-center w-full h-full">
                <p className="w-full h-full text-center">
                  No content to preview
                </p>
              </div>
            )}
          </div> 
        </div> }
      </div> 

      <div
        onClick={updatePromotion}
        className="flex justify-center items-center cursor-pointer text-white w-96  bg-black h-12 mx-auto mt-8 rounded-full"
      >
        อัพเดทโปรโมชั่น
      </div>

      <div className="flex w-full mt-10 justify-center">
        <Table
            className="w-full sm:w-3/4 overflow-x-scroll"
            dataSource={bannerList} 
            columns={columns} 
            pagination={{ position: ['bottomCenter']}}
        />
      </div>
    </main>
  );
}
