import axios from 'axios';
import { useEffect, useState } from 'react';
import SearchForm from '../utils/SearchForm';
import { Table, Typography } from 'antd';
import { useForm } from 'react-hook-form';

const { Text } = Typography;

interface Deposit {
    sum: number;
    daily: number;
    data: DepositData[];
}

interface Withdrawal {
    sum: number;
    daily: number;
    data: WithdrawalData[];
}

interface DepositData {
    amount: string;
    deposit: string;
    transaction_hash: string;
    type: string;
    user_id: string;
    created_at: string;
}

interface WithdrawalData {
    amount: string;
    deposit: string;
    transaction_hash: string;
    type: string;
    user_id: string;
    created_at: string;
}

export default function StatsSummary() {
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            dateFrom: null,
            dateTo: null,
        },
    });
    const [summarySource, setSummarySource] = useState<string>('DEPOSIT');
    const [depositData, setDepositData] = useState<Deposit | null>(null);
    const [withdrawalData, setWithdrawalData] = useState<Withdrawal | null>(null);
    const token = localStorage.getItem('token');

    const depositColumns = [
        {
            title: 'No',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id'
        },
        {
            title: 'Amount',
            dataIndex: 'deposit',
            key: 'deposit',
            render: (text: any, record: any) => (
                <Typography>${record.deposit}</Typography>
            ), 
        },
        {
            title: 'Total',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Transaction Hash',
            dataIndex: 'transaction_hash',
            key: 'transaction_hash',
            render: (text: any, record: any) => (
                <Text copyable={{ text: record.transaction_hash }}>{record.transaction_hash.slice(0, 4)}...{record.transaction_hash.slice(-4)}</Text>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text: any, record: any) => (
                <Text>{record.type.replace(/_/g, ' ')}</Text>
            ),
        },
        {
            title: 'Deposit Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text: any, record: any) => (
                <Text>{new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    timeZone: "UTC",
                }).format(new Date(record.created_at))}</Text>
            ),
        }

    ];

    const withdrawalColumns = [
        {
            title: 'No',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'User ID',
            dataIndex: 'uid',
            key: 'uid'
        },
        {
            title: 'Requested Amount',
            dataIndex: 'requestedAmount',
            key: 'requestedAmount',
            render: (text: any, record: any) => (
                <Typography>${record.requestedAmount}</Typography>
            ), 
        },
        {
            title: 'Fee Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
            render: (text: any, record: any) => (
                <Typography>-${record.feeAmount}</Typography>
            ), 
        },
        {
            title: 'Withdrawal Amount',
            dataIndex: 'deposit',
            key: 'deposit',
            render: (text: any, record: any) => (
                <Typography>${record.amount}</Typography>
            ), 
        },
        {
            title: 'Exchange Amount',
            dataIndex: 'exchangeAmount',
            key: 'exchangeAmount',
            render: (text: any, record: any) => (
                <Typography>${record.exchangeAmount}</Typography>
            ), 
        },
        {
            title: 'Transaction Hash',
            dataIndex: 'tx',
            key: 'tx',
            render: (text: any, record: any) => (
                <Text copyable={{ text: record.tx }}>{record.tx.slice(0, 4)}...{record.tx.slice(-4)}</Text>
            ),
        },
        {
            title: 'Withdrawal Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text: any, record: any) => (
                <Text>{new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    timeZone: "UTC"
                }).format(new Date(record.createdAt))}</Text>
            ),
        }
    ];

    function getDepositSummary(query?: any) {
        const queryParams = query ? `?${new URLSearchParams(query).toString()}` : '';
        axios.get(`${process.env.REACT_APP_ADMIN_API}/stats/deposit${queryParams}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            if(res.status === 200) setDepositData({ ...res.data, data: res.data.data.map((deposit: any, index: number) => ({ ...deposit, index: index + 1 }))});
        })
        .catch((err) => {
            console.log(err);
        });
    }

    function getWithdrawalSummary(query?: any) {
        const queryParams = query ? `?${new URLSearchParams(query).toString()}` : '';
        axios.get(`${process.env.REACT_APP_ADMIN_API}/stats/withdrawal${queryParams}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            if(res.status === 200) setWithdrawalData({ ...res.data, data: res.data.data.map((withdrawal: any, index: number) => ({ ...withdrawal, index: index + 1 }))});
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getDepositSummary();
    }, []);

    function onSearch(query: any) {
        const filteredQuery = Object.fromEntries(Object.entries(query).filter(([_, value]) => value !== null));

        if(summarySource === 'DEPOSIT') getDepositSummary(filteredQuery);
        else if(summarySource === 'WITHDRAWAL') getWithdrawalSummary(filteredQuery);
    }

    function onReset() {
        if(summarySource === 'DEPOSIT') getDepositSummary();
        else if(summarySource === 'WITHDRAWAL') getWithdrawalSummary();
    }

    function onSwitchPage(source: string) {
        reset();
        setSummarySource(source);

        if(source === 'DEPOSIT') getDepositSummary();
        else if(source === 'WITHDRAWAL') getWithdrawalSummary();
    }

    return (
        <main className={`w-full h-[1000px] border`}>
            <div className="w-full h-auto flex justify-center items-center mt-12 md:mt-0">
                <h1 className="text-3xl">Stats Summary | </h1>
                <img className="w-[150px] h-auto" src="/imgs/logo.jpg" alt="brand-logo" />
            </div>

            <div className="flex flex-col flex-auto mt-2 overflow-y-auto bg-haft">
                <div className="w-full flex justify-center">
                    <div className="flex items-center mt-2 p-0.5 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700 w-120">
                    <button
                        className={`px-6 rounded-full w-40 h-8 ${
                            summarySource === 'DEPOSIT'
                                ? 'shadow bg-purple-700 text-white pointer-events-none'
                                : 'bg-transparent'
                        }`}
                        onClick={() => onSwitchPage('DEPOSIT')}
                    >
                        DEPOSIT
                    </button>
                    <button
                        className={`px-6 rounded-full w-40 h-8 ${
                            summarySource === 'WITHDRAWAL'
                                ? 'shadow bg-purple-700 text-white pointer-events-none'
                                : 'bg-transparent'
                        }`}
                        onClick={() => onSwitchPage('WITHDRAWAL')}
                    >
                        WITHDRAWAL
                    </button>
                    </div>
                </div>
            </div>

            <div className='flex justify-center w-full mt-4'>
                <SearchForm 
                    onSearch={onSearch} 
                    onReset={onReset}
                    control={control}
                    handleSubmit={handleSubmit}
                    reset={reset}
                     />
            </div>

            { summarySource === 'DEPOSIT' && depositData && 
                <>
                    <div className="flex flex-row w-full md:w-8/12 mx-auto mt-6 items-center">
                        <p className="sm:text-lg text-s w-1/2 text-left pl-4 sm:pl-32">Daily Deposit Amount</p>
                        <p className="sm:text-lg text-s w-1/2 text-right pr-8 sm:pr-36">${depositData.daily.toLocaleString()}</p>
                    </div>
                    <div className="flex flex-row w-full md:w-8/12 mx-auto mt-2 items-center">
                        <p className="sm:text-lg text-s w-1/2 text-left pl-4 sm:pl-32">Total Deposit Amount</p>
                        <p className="sm:text-lg text-s w-1/2 text-right pr-8 sm:pr-36">${depositData.sum.toLocaleString()}</p>
                    </div>

                    <div className="flex w-full mt-5 justify-center">
                        <Table
                            className="w-full sm:w-3/4 overflow-x-scroll"
                            dataSource={depositData.data} 
                            columns={depositColumns} 
                            pagination={{ position: ['bottomCenter']}}
                        />
                    </div>
                </>
            }

            { summarySource === 'WITHDRAWAL' && withdrawalData && 
                <>
                    <div className="flex flex-row w-full md:w-8/12 mx-auto mt-6 items-center">
                        <p className="sm:text-lg text-s w-1/2 text-left pl-4 sm:pl-32">Daily Withdrawal Amount</p>
                        <p className="sm:text-lg text-s w-1/2 text-right pr-8 sm:pr-36">${withdrawalData.daily.toLocaleString()}</p>
                    </div>
                    <div className="flex flex-row w-full md:w-8/12 mx-auto mt-2 items-center">
                        <p className="sm:text-lg text-s w-1/2 text-left pl-4 sm:pl-32">Total Withdrawal Amount</p>
                        <p className="sm:text-lg text-s w-1/2 text-right pr-8 sm:pr-36">${withdrawalData.sum.toLocaleString()}</p>
                    </div>

                    <div className="flex w-full mt-5 justify-center">
                        <Table
                            className="w-full sm:w-3/4 overflow-x-scroll"
                            dataSource={withdrawalData.data} 
                            columns={withdrawalColumns} 
                            pagination={{ position: ['bottomCenter']}}
                        />
                    </div>
                </>
            }
            

        </main>
    );
}
