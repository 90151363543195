import React from "react";
import {
  useLocation,
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Index from "../components/index/Index";
import { SidebarWithLogo } from "../utility/SideBar";
import { ComplexNavbar } from "../utility/NavBar";
import { SidebarProvider } from "../context/SidebarContext";
import { Login } from "../components/auth/login/Login";
import VerifyUser from "../components/commission/VerifyUser";
import AddPoints from "../components/commission/AddPoints";
import Ticket from "../components/ticket/Ticket";
import AddAds from "../components/ads/AddAds";
import StatsSummary from "../components/stats/Summary";
import LeaderPackage from "../components/package/LeaderPackage";
import StakingPackage from "../components/package/StakingPackage";
import EditProfile from "../components/profile/Edit";
import Reset2FaProfile from "../components/profile/Reset2fa";
import KYCApproval from "../components/kyc/Approval";
import BypassOTP from "../components/register/OTP";
import Wallet from "../components/wallet/Wallet";
import SystemConfiguration from "../components/system/Config";
import QueueConfiguration from "../components/queue/Config";
import QueuePriority from "../components/queue/Priority";
import CreateAccount from "../components/auth/login/CreateAccount";
interface ProtectedRouteProps {
  element: JSX.Element;
  requiredPermissions: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  requiredPermissions,
}) => {
  const permission = localStorage.getItem("permission");

  if (!requiredPermissions.includes(permission || "")) {
    return <Navigate to="/auth/login" />;
  }

  return element;
};

export function RoutesPath() {
  const location = useLocation();
  const noSidebarRoutes = ["/kyc/approval", "/register/otp"];
  const showSidebar = !noSidebarRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <SidebarProvider>
      {showSidebar && <SidebarWithLogo />}
      {showSidebar && <ComplexNavbar />}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route
          path="/stats/summary"
          element={
            <ProtectedRoute
              element={<StatsSummary />}
              requiredPermissions={["admin", "superadmin" , "staff"]}
            />
          }
        />
        <Route
          path="/verify/kyc"
          element={
            <ProtectedRoute
              element={<VerifyUser />}
              requiredPermissions={["admin", "superadmin" , "staff"]}
            />
          }
        />
        <Route
          path="/add/points"
          element={
            <ProtectedRoute
              element={<AddPoints />}
              requiredPermissions={["superadmin"]}
            />
          }
        />
        <Route path="/auth/login" element={<Login />} />
        <Route
          path="/report/tickets"
          element={
            <ProtectedRoute
              element={<Ticket />}
              requiredPermissions={["admin", "superadmin"]}
            />
          }
        />
        <Route
          path="/add/ads"
          element={
            <ProtectedRoute
              element={<AddAds />}
              requiredPermissions={["admin", "superadmin", "staff"]}
            />
          }
        />
        <Route
          path="/add/staking-package"
          element={
            <ProtectedRoute
              element={<StakingPackage />}
              requiredPermissions={["admin", "superadmin"]}
            />
          }
        />
        <Route
          path="/add/leader-package"
          element={
            <ProtectedRoute
              element={<LeaderPackage />}
              requiredPermissions={["admin", "superadmin"]}
            />
          }
        />
        <Route
          path="/profile/edit"
          element={
            <ProtectedRoute
              element={<EditProfile />}
              requiredPermissions={["admin", "superadmin", "staff"]}
            />
          }
        />
        <Route
          path="/profile/reset-2fa"
          element={
            <ProtectedRoute
              element={<Reset2FaProfile />}
              requiredPermissions={["admin", "superadmin", "staff"]}
            />
          }
        />
        <Route 
          path="/kyc/approval/:code" 
          element={
            <KYCApproval />
          } 
        />
        <Route 
          path="/register/otp/:code" 
          element={
            <BypassOTP />
          } 
        />
        <Route
          path="/edit/wallet"
          element={
            <ProtectedRoute
              element={<Wallet />}
              requiredPermissions={["admin", "superadmin", "staff"]}
            />
          }
        />
        <Route
          path="/system/configuration"
          element={
            <ProtectedRoute
              element={<SystemConfiguration />}
              requiredPermissions={["superadmin"]}
            />
          }
        />
        <Route
          path="/queue/configuration"
          element={
            <ProtectedRoute
              element={<QueueConfiguration />}
              requiredPermissions={["superadmin"]}
            />
          }
        />
        <Route
          path="/queue/priority"
          element={
            <ProtectedRoute
              element={<QueuePriority />}
              requiredPermissions={["superadmin"]}
            />
          }
        />
        <Route
          path="/create/account"
          element={
            <ProtectedRoute
              element={<CreateAccount />}
              requiredPermissions={["superadmin"]}
            />
          }
        />
      </Routes>
    </SidebarProvider>
  );
}

const App: React.FC = () => {
  return (
    <Router>
      <RoutesPath />
    </Router>
  );
};

export default App;
