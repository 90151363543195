import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Table, Badge } from 'antd';
import LineNotify from '../../utility/LineNotify';
import AdminData from '../../utility/AdminData';

interface Transaction {
    from: string;
    to: string;
    amount: string;
    cashtype: string;
    date: string;
}

interface Admin {
    uname: string;
    uid: string;
    created_at: string;
    role: string;
    f_name: string | null;
    m_name: string | null;
    l_name: string | null;
    profile_image: string;
}

interface queueListData {
    price: number;
    totalAmount: string | number;
    marketAmount: string | number;
    queueAmount: string | number;
}

export default function QueuePriority() {
    const [searchValue, setSearchValue] = useState('');
    const [priceList, setPriceList] = useState([]);
    const [queueList, setQueueList] = useState([]);
    const [selectedPriceList, setSelectedPriceList] = useState(0);
    const [selectedQueueList, setSelectedQueueList] = useState<queueListData | null>(null);
    const [priorityUser, setPriorityUser] = useState(null);
    const [queueData, setQueueData] = useState([]);
    const [selectPriorityUser, setSelectPriorityUser] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [adminData, setAdminData] = useState<Admin | null>(null);
    const token = localStorage.getItem('token');

    const columns = [
        {
            title: 'Order',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Queue ID',
            dataIndex: 'transaction_id',
            key: 'transaction_id',
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'Amount of sale',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            render: (text: any, record: any) => {
                // Customize rendering based on the value of 'priority'
                if (record.priority) {
                    return <Badge status="success" text="Priority" />;
                } else {
                    return <Badge status="error" text="Normal" />;
                }
            },
        },
    ];

    function inputValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.value;
        setSearchValue(value);
    }

    useEffect(() => {
        AdminData();
        QueueInfo();
    }, []);

    function AdminData(): any {
        const token = localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_ADMIN_API}/etc/my-profile`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            const data = res.data;
            setAdminData(data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    function QueueInfo(): any {
        const token = localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_ADMIN_API}/queue/queue-info`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            const { priceList, queueList } = res.data;
            setPriceList(priceList);
            setQueueList(queueList);

            setSelectedPriceList(priceList[0]);
            setSelectedQueueList(queueList[0]);
            loadQueueData(priceList[0]);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    function setSelectedPrice(index: any) {
        setSelectedPriceList(priceList[index]);
        setSelectedQueueList(queueList[index]);
        loadQueueData(priceList[index]);
    }

    function loadQueueData(price: any, searchQuery: string | null = null) {
        const token = localStorage.getItem('token');

        let path = `${process.env.REACT_APP_ADMIN_API}/queue/queue-list?price=${price}`
        if(searchQuery) path += `&user=${searchQuery}`

        axios.get(path, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            const { queue, priority } = res.data;
            setQueueData(queue);
            setPriorityUser(priority);
        })
        .catch((err) => {
            console.log(err);
        });
    }
    
    function handleSearch() {
        loadQueueData(selectedPriceList, searchValue);
    }

    function addPriority() {
        Swal.fire({
            title: `Add Priority Queue User ${selectPriorityUser}`,
            text: "ยืนยันเพื่อ Add Priority Queue",
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed) {
                    axios.patch(`${process.env.REACT_APP_ADMIN_API}/queue/priority/add`,
                        { 
                            userId: selectPriorityUser,
                            price: selectedPriceList
                        },
                        { headers: { Authorization: `Bearer ${token}` }}
                    )
                .then(() => {
                    Swal.fire({
                        title: 'Add Priority Queue สำเร็จ',
                        icon: 'success'
                    });
                
                    setSelectPriorityUser(null);
                    setSelectedRowKeys([]);
                    loadQueueData(selectedPriceList);
                })
                .catch((err) => {
                    Swal.fire({
                        title: 'Add Priority Queue Error',
                        icon: 'error'
                    });
                });
            }
        });
    }

    function addTokens() {
        Swal.fire({
            title: "Enter token amount",
            input: "text",
            inputAttributes: { autocapitalize: "on" },
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed && result.value) {
                axios.post(`${process.env.REACT_APP_ADMIN_API}/queue/token/add`,
                        { 
                            amount: parseInt(result.value),
                            value: selectedPriceList
                        },
                        { headers: { Authorization: `Bearer ${token}` }}
                    )
                .then(() => {
                    Swal.fire({
                        title: 'Add Tokens สำเร็จ',
                        icon: 'success'
                    });
                
                    QueueInfo();
                })
                .catch((err) => {
                    Swal.fire({
                        title: err?.response?.data?.message || 'Add tokens Error',
                        icon: 'error'
                    });
                });
            }
        });
    }

    function removePriority() {
        const token = localStorage.getItem('token');

        Swal.fire({
            title: `Remove Priority Queue User ${priorityUser}`,
            text: "ยืนยันเพื่อ Remove Priority Queue",
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.patch(`${process.env.REACT_APP_ADMIN_API}/queue/priority/remove`, 
                    { price: selectedPriceList }, 
                    { headers: { Authorization: `Bearer ${token}` }}
                )
                .then((res) => {
                    Swal.fire({
                        title: 'Remove Priority Queue สำเร็จ',
                        icon: 'success'
                    });

                    loadQueueData(selectedPriceList);
                })
                .catch((err) => {
                    console.log(err);
                    Swal.fire({
                        title: 'Remove Priority Queue Error',
                        icon: 'error'
                    });
                });
            }
        });
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            const userID = selectedRows[0].user_id;
            setSelectPriorityUser(userID);
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record: any) => ({
            name: record.user_id,
        }),
    };

    return (
        <main className={`w-full h-[1000px] border`}>
            <div className="w-full h-auto flex justify-center items-center mt-12 md:mt-0">
                <h1 className="text-3xl">Queue Priority | </h1>
                <img className="w-[150px] h-auto" src="/imgs/logo.jpg" alt="brand-logo" />
            </div>

            <div className="w-full h-auto flex flex-row justify-center mt-2.5 gap-x-2.5 mb-4">
                <button
                    className={`p-3 w-32 border rounded-xl text-white ${selectedPriceList === priceList[0] ? 'bg-purple-400 ' : 'bg-gray-400'}`}
                    onClick={() => setSelectedPrice(0)}
                >
                    {`$${priceList[0]}`}
                </button>

                <button
                    className={`p-3 w-32 border rounded-xl text-white ${selectedPriceList === priceList[1] ? 'bg-purple-400 ' : 'bg-gray-400'}`}
                    onClick={() => setSelectedPrice(1)}
                >
                     {`$${priceList[1]}`}
                </button>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2 text-left pl-4 sm:pl-32">Total market amount</p>
                <p className="sm:text-lg text-s w-1/2 text-right pr-8 sm:pr-36">{selectedQueueList?.totalAmount.toLocaleString()} Tokens</p>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2 text-left pl-4 sm:pl-32">Trader account amount</p>
                <p className="sm:text-lg text-s w-1/2 text-right pr-8 sm:pr-36">{selectedQueueList?.marketAmount.toLocaleString()} Tokens</p>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2 text-left pl-4 sm:pl-32">User token amount</p>
                <p className="sm:text-lg text-s w-1/2 text-right pr-8 sm:pr-36">{selectedQueueList?.queueAmount.toLocaleString()} Tokens</p>
            </div>

            { selectedPriceList === priceList[0] && <div className="w-full h-auto flex flex-row justify-center mt-2.5 gap-x-2.5 pt-4">
                    <button
                        className={`p-3 sm:w-64 w-48 border rounded-xl text-white bg-purple-400`}
                        onClick={() => addTokens()}
                    >
                        Add tokens
                    </button>
                </div>
            }

            <div className="w-full h-auto flex flex-row justify-center mt-2.5 gap-x-2.5 pt-4">
                <button
                    className={`p-3 sm:w-64 w-48 border rounded-xl text-white ${!priorityUser ? 'bg-purple-400 ' : 'bg-gray-400'}`}
                    disabled={!!priorityUser || !selectPriorityUser}
                    onClick={() => addPriority()}
                >
                    Add priority
                </button>

                <button
                    className={`p-3 sm:w-64 w-48 border rounded-xl text-white ${priorityUser ? 'bg-purple-400 ' : 'bg-gray-400'}`}
                    disabled={!priorityUser}
                    onClick={() => removePriority()}
                >
                    Remove priority
                </button>
            </div>

            <div className="flex w-10/12 md:w-8/12 mx-auto mt-6">
                <input type="text" placeholder="ค้นหา GRIT ID" className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl" onChange={(e) => inputValue(e)} />
                <button className="ml-4 bg-purple-500 text-white rounded-xl px-4" onClick={handleSearch}>
                    Search
                </button>
            </div>

            <div className="flex mt-2 justify-center">
                <Table
                    rowSelection={{
                        type: 'radio',
                        ...rowSelection,
                    }}
                    className="sm:w-2/3 w-full overflow-x-scroll"
                    dataSource={queueData} 
                    columns={columns} 
                    pagination={{ position: ['bottomCenter']}}
                />
            </div>
            
        </main>
    );
}
